/* compiled from /scss/_footer.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";



#pagetop {
  position: fixed;
  bottom: 24px;
  right: 22px;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  transition: opacity .5s $cubic;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, .12));
  @include mq(tbl) {
    bottom: 45px;
    right: 90px;
  }
  @include mq(pc) {
    bottom: 85px;
    right: 50%;
    transform: translateX(620px);
  }
  img {
    width: 50px;
    transition: opacity .5s $cubic;
    @include mq(tbl) {
      &:hover {
        opacity: .5;
      }
    }
  }
  &.on {
    opacity: 1;
  }
}

footer {
  color: white;
  .top {
    padding-top:24px;
    @include container;
    @include mq(tbl) {
      padding: 75px 0 0;
      display: flex;
      justify-content: space-between;
    }
    nav {
      padding: 0 17px;
      @include mq(tbl) {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
      }
      @include mq(pc) {
        width: 1100px;
      }
      .unit {
        border-bottom: 1px solid #4a628f;
        padding-bottom: 5px;
        @include mq(tbl) {
          border-bottom: none;
          padding-bottom: 80px;
          width: 20%;
        }
        .en & {
          &.about {
            ul {
              li {
                a {
                  text-transform: uppercase;
                  letter-spacing: 4px;
                }
              }
            }
          }
          &.featured {
            @include mq(tbl) {
              margin-left: 20%;
            }
          }
        }
        .ja & {
          &.about {
            ul {
              li {
                a {
                  text-transform: uppercase;
                  letter-spacing: 3px;
                }
              }
            }
          }
        }
        h2 {
          padding: 25px 0 20px;
          @include fs(17,17);
          text-transform: uppercase;
          letter-spacing: 5px;
          @include en_regular;
          @include mq(tbl) {
            padding: 5px 0 15px;
            @include fs(15,15);
            @include en_medium;
            letter-spacing: 4px;
          }
        }
        ul {
          display: none;
          padding-bottom: 15px;
          @include mq(tbl) {
            display: block !important;
            height: auto !important;
            padding-bottom: 0;
          }
          li {
            a {
              display: block;
              padding: 11px;
              @include fs(15,15);
              @include en_regular;
              color: white;
              letter-spacing: 1px;
              color: #d9d9d9;
              @include mq(tbl) {
                padding: 6px 0;
                @include fs(13,21);
                .ja & {
                  @include fs(12,20);
                  font-weight: bold;
                }
              }
            }
            &.sub {
              padding-left: 14px;
              @include mq(tbl) {
                padding-left: 10px;
              }
              a {
                @include fs(12,18);
                padding: 10px 10px 10px 21px;
                position: relative;
                @include mq(tbl) {
                  padding: 6px 0 6px 11px;
                  .ja & {
                    @include fs(11,18);
                  }
                }
                &:before {
                  content: '-';
                  position: absolute;
                  display: block;
                  left: 10px;
                  @include mq(tbl) {
                    left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .sns {
      display: none;
      @include mq(tbl) {
        // display: flex;
        width: 100px;
        padding-top: 5px;
        justify-content: flex-end;
      }
      li {
        margin-right: 25px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 31px;
        }
      }
    }
  }
  .bottom {
    padding: 24px 0 30px;
    @include container;
    @include mq(tbl) {
      padding: 60px 0 30px;
    }
    .inner {
      padding: 0 17px;
      @include mq(tbl) {
        padding: 0;
        display: flex;
        justify-content: space-between;
      }
      .util {
        @include mq(tbl) {
          display: flex;
        }
        li {
          @include mq(tbl) {
            margin-right: 55px;
          }
          a {
            color: white;
            display: block;
            padding: 11px 0;
            text-transform: uppercase;
            letter-spacing: 3px;
            @include fs(13,13);
            @include en_regular;
            @include mq(tbl) {
              padding: 0;
              @include fs(10,10);
            }
          }
        }
      }
      .sns {
        display: none;
        margin-top: 25px;
        @include mq(tbl) {
          display: none;
        }
        li {
          margin-left: 50px;
          display: inline-block;
          &:first-child {
            margin-left: 0;
          }
          img {
            width: 31px;
          }
        }
      }
      .copyright {
        @include en_medium;
        @include fs(9,9);
        letter-spacing: 2px;
        margin: 38px 0 0;
        @include mq(tbl) {
          margin-top: 0;
        }
      }
    }
  }
}
