/* compiled from /scss/_modules.scss */

@charset "UTF-8";
@import "variables";
@import "mixins";



.m_feature_title {
  padding-top: 45px;
  text-align: center;
  @include mq(tbl) {
    padding-top: 65px;
  }
  .inner {
    background-color: #6f9ba9;
    padding: 40px 15px 42px;
    @include mq(tbl) {
      padding: 55px 0px 60px;
    }
    .title {
      color: white;
      @include fs(25,25);
      letter-spacing: 8px;
      position: relative;
      padding: 0 8px 10px 12px;
      display: inline-block;
      text-transform: uppercase;
      &:after {
        content: '';
        display: block;
        background-color: white;
        left: 0;
        right: 4px;
        height: 3px;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
.m_feature_mv {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 240px;
  @include mq(tb) {
    height: 300px;
  }
  @include mq(tbl) {
    display: none;
  }
}
.m_feature_wrap {
  margin-top: 45px;
  margin-bottom: 20px;
  @include mq(tbl) {
    margin-top: 50px;
    margin-bottom: 110px;
  }
  .container {
    padding-left: 35px;
    padding-right: 35px;
    @include mq(tbl) {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .inner {
      @include mq(tbl) {
        flex: 1;
        padding-left: 50px;
      }
      @include mq(pc) {
        flex: 0 1 auto;
        padding-left: 0;
        width: 1000px;
      }
      > ul {
        @include newslist;
        margin-bottom: 50px;
      }
      .lnav {
        @include lnav;
      }
      .title {
        padding: 0 0 20px;
        @include mq(tbl) {
          padding: 0 30px 40px;
        }
        .date {
          @include fs(14,24);
          letter-spacing: 2px;
          padding-bottom: 10px;
          @include en_regular;
        }
        h1 {
          @include fs(18,28);
          letter-spacing: 1px;
          @include mq(tbl) {
            @include fs(20,30);
            .ja & {
              letter-spacing: 2px;
            }
          }
        }
      }
      .mainvisual {
        display: none;
        @include mq(tbl) {
          display: block;
          padding-bottom: 30px;
        }
      }
      article {
        margin-top: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 40px;
        @include article;
        @include mq(tbl) {
          padding: 0 30px 60px;
          margin-bottom: 60px;
        }
      }
    }
    nav {
      margin-top: 45px;
      @include mq(tbl) {
        width: 195px;
        margin-top: 0;
        padding-top: 5px;
        padding-left: 30px;
      }
      h3 {
        a {
          display: block;
          border: 1px solid #e6e6e6;
          padding: 28px 15px;
          text-transform: uppercase;
          @include fs(15,15);
          letter-spacing: 4px;
          text-align: center;
          @include mq(tbl) {
            display: inline;
            border: none;
            padding: 0;
          }
        }
      }
      ul {
        display: none;
        @include mq(tbl) {
          display: block;
          list-style: disc;
          margin-left: 20px;
          margin-top: 13px;
        }
        li {
          @include fs(15,15);
          letter-spacing: 4px;
          a {
            display: block;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
