$screen-xs-min: 400px;
$screen-sm-min: 768px;
$container: 1200px;

$main: #183670;
$sub: #029087;
$white: #fff;
$bg :#FBF9F2;
$black: #333;
$red: red;

$pt: 60px;
$ps: 15px;

$narrow: 665px;

$imagePath: '/images/';

$breakpoints: (
  'sp': 'screen and (min-width: 580px)',
  'tb': 'screen and (min-width: 768px)',
  'tbl': 'screen and (min-width: 1060px)',
  'pc': 'screen and (min-width: 1300px)',
) !default;

$cubic: cubic-bezier(.04,.92,.29,1);

$photo-transition: transform 1.5s $cubic, opacity 1.5s $cubic;

$path-transition: $cubic 1.5s forwards;
