/* compiled from /scss/_modules.scss */

@charset "UTF-8";
@import "variables";
@import "mixins";



.m_news_title {
  padding-top: 90px;
  text-align: center;
  @include mq(tbl) {
    padding-top: 70px;
  }
  .title {
    color: $main;
    @include fs(25,25);
    letter-spacing: 8px;
    position: relative;
    padding: 0 8px 10px 12px;
    display: inline-block;
    text-transform: uppercase;
    &:after {
      content: '';
      display: block;
      background-color: $main;
      left: 0;
      right: 4px;
      height: 3px;
      position: absolute;
      bottom: 0;
    }
  }
}
.m_news_list {
  margin-top: 45px;
  margin-bottom: 20px;
  @include mq(tbl) {
    margin-bottom: 110px;
  }
  .container {
    padding-left: 35px;
    padding-right: 35px;
    @include mq(tbl) {
      padding-left: 0;
      padding-right: 0;
    }
    ul {
      @include newslist;
    }
  }
}
.m_news_detail {
  margin-top: 50px;
  @include mq(tbl) {
    margin-top: 30px;
  }
  .container {
    .title {
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      padding: 20px 0;
      @include mq(tbl) {
        padding: 20px 15px;
      }
      .date {
        text-align: center;
        @include fs(14,24);
        letter-spacing: 2px;
        padding-bottom: 10px;
        @include en_regular;
        @include mq(tbl) {
          text-align: left;
        }
      }
      h1 {
        @include fs(18,28);
        letter-spacing: 1px;
        @include mq(tbl) {
          @include fs(20,30);
          .ja & {
            letter-spacing: 2px;
          }
        }
      }
    }
    article {
      margin-top: 30px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 40px;
      @include article;
      @include mq(tbl) {
        padding: 0 15px 60px;
        margin-bottom: 110px;
      }
      .movie {
        margin-top: 45px;
        max-width: 600px;
        margin-inline: auto;
        @include mq(tbl) {
          margin-top: 90px;
          max-width: 800px;
        }
        @include mq(pc) {
          max-width: 960px;
        }
        video {
          width: 100%;
        }
      }
    }
  }
}
