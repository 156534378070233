/* compiled from /scss/_modules.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";



.m_mv {
  + .m_contents {
    padding-top: 50px;
  }
}
.m_contents {
  padding-bottom: 20px;
  color: #4c4c4c;
  letter-spacing: .5px;
  @include mq(tbl) {
    margin-top: 30px;
  }
  i {
    font-style: italic;
  }
  a {
    &[target="_blank"] {
      word-break: break-all;
      &:after {
        content: '';
        width: 16px;
        height: 14px;
        background-image:url(/images/newwin.png);
        background-size: 16px auto;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: -2px;
        margin-left: 5px;
      }
    }
  }
  h2 {
    @include fs(30, 35);
    margin-bottom: .75em;
    @include mq(tbl) {
      @include fs(50, 60);
    }
    &.tagline {
      color: $main;
    }
    &.logo {
      width: 144px;
      text-align: center;
      margin: 0 auto;
      @include mq(tbl) {
        width: 170px;
      }
      .ja & {
        width: 210px;
        @include mq(tbl) {
          width: 245px;
        }
      }
    }
  }
  p {
    @include fs(16, 30);
    @include en_medium;
    padding-bottom: 1em;
    @include mq(tbl) {
      text-align: justify;
    }
    .ja & {
      @include ja;
      font-weight: normal;
      @include fs(16, 34);
      letter-spacing: 1px;
    }
    + .min {
      padding-top: 40px;
    }
    &.t_center_pc {
      @include mq(tbl) {
        text-align: center;
      }
    }
    &.catch {
      color: $sub;
      @include fs(30, 42);
      padding: 30px 0 80px;
      text-align: center;
      @include en;
      letter-spacing: 1px;
      @include mq(sp) {
        @include fs(40, 60);
      }
      @include mq(tb) {
        @include fs(50, 75);
      }
      @include mq(tbl) {
        @include fs(77, 105);
        padding: 30px 0 90px;
      }
    }
    &.link {
      margin-top: 25px;
      @include mq(tbl) {
        margin-top: 45px;
      }
      a {
        display: block;
        background-color: $sub;
        color: white;
        padding: 30px 15px;
        @include fs(16, 16);
        text-align: center;
        transition: background .3s ease;
        @include mq(tbl) {
          @include fs(20, 20);
          padding: 60px 30px;
          &:hover {
            background-color: $main;
            opacity: 1;
          }
        }
        &:after {
          width: 18px;
          height: 17px;
          background-image:url(/images/newwin_white.png);
          background-size: 18px auto;
        }
      }
    }
  }
  figure {
    padding-bottom: 30px;
    @include mq(tbl) {
      padding: 10px 0 40px;
    }
    figcaption {
      padding-top: 15px;
      @include fs(14, 28);
      text-align: center;
      letter-spacing: 1px;
      .ja & {
        @include ja;
        font-weight: normal;
      }
      @include mq(tbl) {
        @include fs(16, 32);
      }
      &.right {
        text-align: right;
        .ja & {
          @include fs(13, 26);
        }
      }
    }
  }
  .min {
    text-align: center;
    @include mq(tb) {
      figure {
        display: inline-block;
        img {
          width: auto;
        }
      }
    }
  }
  table {
    width: 100%;
    &.about {
      margin-top: 40px;
      border-bottom: 1px solid #e6e6e6;
      @include mq(tbl) {
        margin-top: 55px;
      }
      th,td {
        @include fs(16, 28);
        display: block;
        @include mq(tbl) {
          @include fs(16, 32);
          display: table-cell;
          vertical-align: top;
          border-top: 1px solid #e6e6e6;
        }
        .ja & {
          @include ja;
          font-weight: normal;
          @include fs(16, 30);
          letter-spacing: 1px;
        }
        &.margin {
          @include mq(tbl) {
            padding-top: 2em;
          }
        }
      }
      th {
        border-top: 1px solid #e6e6e6;
        @include en;
        padding-top: 18px;
        @include mq(tbl) {
          white-space: nowrap;
          padding: 18px 60px 18px 45px;
        }
        .ja & {
          font-weight: bold;
          @include mq(tbl) {
            padding-left: 100px;
            width: 40%;
          }
        }
      }
      td {
        padding-top: 5px;
        margin-bottom: 1.25em;
        @include mq(tbl) {
          padding: 18px 0 18px 0;
          margin: 0;
        }
      }
    }
    &.openscience {
      margin-top: 20px;
      border-bottom: 1px solid #ededed;
      @include mq(tbl) {
        margin-top: 55px;
      }
      caption {
        height: 1px;
        background-color: #ededed;
        @include mq(tbl) {
          display: none;
        }
      }
      thead {
        tr {
          th {
            display: none;
            @include mq(tbl) {
              display: table-cell;
              background-color: #b3b3b3;
              color: white;
              padding: 20px 18px;
              @include fs(16, 35);
              border-right: 1px solid #e6e6e6;
              &:last-child {
                border-right: none;
              }
              .ja & {
                font-weight: bold;
              }
            }
            &:first-child {
              width: 35%;
            }
            &:nth-child(2) {
              width: 22%;
            }
            &:nth-child(3) {
              width: 43%;
            }
          }
        }
      }
      tbody {
        tr {
          &:first-child {
            td {
              &:first-child {
                h3 {
                  margin-top: 20px;
                }
              }
            }
          }
          &:last-child {
            td {
              &:last-child {
                padding-bottom: 20px;
              }
            }
          }
          &:nth-child(even){
            @include mq(tbl) {
              background-color: #ebebeb;
            }
          }
          td {
            h3 {
              margin-top: 10px;
              color: #b3b3b3;
              .ja & {
                font-weight: bold;
              }
              @include mq(tbl) {
                display: none;
              }
            }
            &:first-child {
              h3 {
                background-color: #b3b3b3;
                color: white;
                padding: 7px;
                margin: 30px 0 10px;
              }
            }
          }
          td {
            @include fs(16, 28);
            display: block;
            text-align: center;
            @include mq(tbl) {
              @include fs(16, 35);
              display: table-cell;
              vertical-align: top;
              padding: 22px;
              border-top: 1px solid #e6e6e6;
              border-right: 1px solid #e6e6e6;
              &:last-child {
                border-right: none;
              }
            }
            p {
              text-align: center;
              padding-bottom: 0;
              @include mq(tbl) {
                text-align: left;
              }
            }
          }
        }
      }
    }
    &.series {
      margin-top: 35px;
      @include mq(tbl) {
        margin-top: 50px;
        border-bottom: 1px solid #e6e6e6;
      }
      caption {
        @include fs(22, 32);
        text-align: center;
        font-weight: bold;
        padding-bottom: 30px;
        @include mq(tbl) {
          @include fs(25, 35);
        }
      }
      tr {
        &:nth-child(even){
          @include mq(tbl) {
            background-color: #ebebeb;
          }
        }
        th {
          display: block;
          background-color: #b3b3b3;
          color: white;
          padding: 14px 18px;
          @include fs(16, 16);
          text-align: center;
          @include en;
          vertical-align: top;
          @include mq(tbl) {
            @include fs(16, 28);
            display: table-cell;
            border-top: 1px solid white;
            padding: 14px 20px;
            text-align: left;
            &:last-child {
              border-right: none;
            }
          }
        }
        td {
          @include fs(16, 28);
          display: block;
          text-align: center;
          padding: 14px 0 30px;
          @include mq(tbl) {
            @include fs(16, 28);
            display: table-cell;
            vertical-align: top;
            text-align: left;
            padding: 14px 30px;
            border-top: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
          }
          ul {
            display: flex;
            justify-content: center;
            @include mq(tbl) {
              justify-content: flex-start;
              padding: 5px 0;
            }
            li {
              padding: 0 16px;
              @include mq(tbl) {
                display: flex;
                padding: 0;
                margin-right: 50px;
              }
              p {
                padding-bottom: 12px;
                @include mq(tbl) {
                  margin-right: 22px;
                  padding-bottom: 0;
                }
              }
              img {
                width: 120px;
              }
            }
          }
        }
      }
    }
    &.microbial {
      margin-top: 45px;
      border-bottom: 1px solid #ededed;
      @include mq(tbl) {
        margin-top: 55px;
      }
      caption {
        height: 1px;
        background-color: #ededed;
        @include mq(tbl) {
          display: none;
        }
      }
      thead {
        tr {
          th {
            display: none;
            @include mq(tbl) {
              display: table-cell;
              background-color: #b3b3b3;
              color: white;
              padding: 20px 18px;
              @include fs(16, 28);
              text-align: center;
              border-right: 1px solid #e6e6e6;
              &:last-child {
                border-right: none;
              }
            }
          }
        }
      }
      tbody {
        tr {
          border-top: 1px solid #e6e6e6;
          display: block;
          padding-bottom: 25px;
          padding-top: 5px;
          padding-left: 55px;
          height: 90px;
          overflow: hidden;
          position: relative;
          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9px 5px 0 5px;
            border-color: #b3b3b3 transparent transparent transparent;
            position: absolute;
            left: 12px;
            top: 42px;
            transition: transform .2s ease-in-out;
            @include mq(tbl) {
              display: none;
            }
          }
          @include mq(tbl) {
            display: table-row;
            height: auto;
            overflow: visible;
          }
          &.active {
            height: auto;
            &:before {
              transform: rotateZ(-90deg);
            }
            td {
              opacity: 1;
            }
          }
          &:nth-child(even){
            @include mq(tbl) {
              background-color: #ebebeb;
            }
          }
          td {
            @include fs(16, 28);
            display: block;
            opacity: 0;
            transition: opacity .2s ease-in-out;
            .alpha {
              font-family: Arial;
            }
            @include mq(tbl) {
              opacity: 1;
              display: table-cell;
              vertical-align: top;
              padding: 18px 15px;
              text-align: center;
              border-top: 1px solid #e6e6e6;
              border-right: 1px solid #e6e6e6;
              &:last-child {
                border-right: none;
              }
              &.formula {
                vertical-align: middle;
              }
            }
            &:first-child {
              opacity: 1;
            }
            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(6) {
              white-space: nowrap;
              &.wrap {
                white-space: normal;
              }
            }
            h3 {
              margin-top: 10px;
              color: #b3b3b3;
              @include mq(tbl) {
                display: none;
              }
            }
            p {
              text-align: left;
              padding-bottom: 0;
              @include mq(tbl) {
                text-align: center;
                @include fs(16, 28);
                .ja & {
                  @include fs(15, 25);
                }
              }
            }
            img {
              width: 210px;
            }
            i {
              font-style: italic;
            }
          }
        }
      }
    }
  }
  .microbial_footer {
    text-align: center;
    border-bottom: 1px solid #ededed;
    @include fs(16, 28);
    margin-top: 1em;
    @include mq(tbl) {
      margin-bottom: 40px;
    }
    p {
      @include en_regular;
      text-align: center;
      &.rotate {
        transform: rotateZ(90deg);
        padding-bottom: 0;
        transform-origin: center;
        display: inline-block;
      }
      strong {
        @include en;
      }
    }
  }
  .sign {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
    @include mq(tbl) {
      margin-bottom: 0;
    }
    .inner {
      display: inline-block;
      h3 {
        img {
          width: 90px;
          @include mq(tbl) {
            width: 120px;
          }
          .ja & {
            width: 150px;
            @include mq(tbl) {
              width: 185px;
            }
          }
        }
      }
      .position {
        @include fs(14, 28);
        text-align: left;
        margin-top: 5px;
        @include en;
        @include mq(tbl) {
          @include fs(20, 28);
          text-align: left;
          margin-top: 10px;
        }
        .ja & {
          margin-top: 7px;
          @include ja;
          font-weight: normal;
          @include fs(16, 32);
          letter-spacing: 2px;
          display: inline-block;
          margin-right: 10px;
          @include mq(tbl) {
            @include fs(20, 30);
            margin-top: 15px;
          }
        }
      }
      .name {
        @include fs(20, 28);
        text-align: right;
        @include mq(tbl) {
          @include fs(25, 40);
        }
        .ja & {
          margin-top: 7px;
          @include ja;
          font-weight: normal;
          @include fs(16, 32);
          letter-spacing: 2px;
          display: inline-block;
          @include mq(tbl) {
            @include fs(20, 30);
            margin-top: 15px;
          }
        }
      }
    }
  }
  blockquote {
    margin-top: 20px;
    background-color: #ebebeb;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
    padding: 12px 16px;
    @include fs(13, 22);
    @include en_regular;
    @include mq(tbl) {
      margin-top: 30px;
      @include fs(14, 24);
      padding: 26px 30px;
    }
    ol {
      list-style: decimal;
      margin-left: 18px;
    }
  }
  .prize_logo {
    margin-bottom: 25px;
    @include mq(tb) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
    }
    @include mq(tbl) {
      margin-top: 40px;
      margin-bottom: 75px;
    }
    li {
      text-align: center;
      padding: 25px 0;
      @include mq(tb) {
        padding: 0 40px;
      }
      .science {
        width: 250px;
        @include mq(tbl) {
          width: 310px;
        }
      }
      .microbiome {
        width: 220px;
        @include mq(tbl) {
          width: 270px;
        }
      }
    }
  }
  .prize_title {
    margin-top: 1em;
    @include fs(18,36);
  }
  .prize_title_wrap {
    @include mq(tbl) {
      padding: 10px 0 70px;
      display: flex;
      p {
        width: 50%;
        padding-right: 30px;
      }
    }
  }
  .prize_unit {
    border-top: 1px solid #e6e6e6;
    padding-bottom: 40px;
    @include mq(tbl) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-top: 70px;
    }
    &:first-of-type {
      margin-top: 50px;
    }
    &:last-of-type {
      border-bottom: 1px solid #e6e6e6;
    }
    .image {
      text-align: center;
      @include mq(tbl) {
        width: 260px;
      }
      &.noster {
        padding: 80px 0 75px;
        @include mq(tbl) {
          padding: 0;
        }
        img {
          width: 188px;
        }
      }
      &.science {
        padding: 55px 0 50px;
        @include mq(tbl) {
          padding: 0;
        }
        img {
          width: 185px;
        }
      }
    }
    .text {
      @include mq(tbl) {
        flex: 1;
        padding-right: 45px;
      }
      h3 {
        text-align: center;
        @include fs(25,25);
        letter-spacing: 2px;
        margin-bottom: 1em;
        @include mq(tbl) {
          text-align: left;
        }
      }
    }
  }
  .metabolite {
    padding-top: 25px;
    @include mq(tbl) {
      padding-top: 55px;
      padding-bottom: 55px;
      display: flex;
      justify-content: space-between;
    }
    .unit {
      padding: 25px 0 35px;
      @include mq(tbl) {
        padding: 0;
        width: 47%;
      }
      h3 {
        text-align: center;
        @include fs(16,26);
        @include mq(tbl) {
          @include fs(18,35);
        }
      }
      figure {
        text-align: center;
        margin-top: 35px;
        img {
          max-width: 320px;
          @include mq(tbl) {
            max-width: 396px;
          }
        }
      }
      /*
      table {
        margin-top: 10px;
        width: 100%;
        border-bottom: 1px solid #e6e6e6;
        @include mq(tbl) {
          margin-top: 15px;
        }
        tr {
          &:nth-child(odd) {
            background-color: #ebebeb;
          }
          &.short {
            td {
              display: block;
              @include mq(tbl) {
                display: table-cell;
              }
              .hidden-over-tb {
                margin-left: auto;
                display: inline-block;
                text-align: right;
              }
              .inner {
                display: flex;
                justify-content: space-between;
                font-style: normal;
              }
              &:last-child {
                display: none;
                @include mq(tbl) {
                  display: table-cell;
                }
              }
            }
          }
          th {
            display: block;
            background-color: #b3b3b3;
            color: white;
            @include fs(16,26);
            padding: 15px 10px;
            font-weight: bold;
            @include mq(tbl) {
              display: table-cell;
              padding: 17px 20px;
              border-right: 1px solid #e6e6e6;
            }
            .hidden-over-tb {
              margin-left: auto;
              display: inline-block;
              text-align: right;
            }
            .inner {
              display: flex;
              justify-content: space-between;
            }
            &:last-child {
              display: none;
              @include mq(tbl) {
                display: table-cell;
                border-right: none;
                white-space: nowrap;
              }
            }
          }
          td {
            display: block;
            padding: 15px 10px;
            @include fs(16,26);
            @include mq(tbl) {
              display: table-cell;
              border-right: 1px solid #e6e6e6;
              padding: 17px 20px;
            }
            &:first-child {
              font-style: italic;
              border-top: 1px solid #e6e6e6;
              @include mq(tbl) {
                width: 100%;
              }
            }
            &:last-child {
              padding-top: 0;
              text-align: right;
              @include mq(tbl) {
                padding-top: 17px;
                border-right: none;
                text-align: center;
                border-top: 1px solid #e6e6e6;
              }
            }
          }
        }
      }
      */
    }
  }
  .series_list {
    border-top: 1px solid #e6e6e6;
    padding-top: 40px;
    margin-top: 35px;
    @include mq(tbl) {
      @include fs(25, 25);
      border-top: none;
      padding-top: 0;
      margin-top: 75px;
    }
    h2 {
      @include fs(22, 22);
      text-align: center;
      margin-bottom: 0;
      letter-spacing: 1px;
      @include mq(tbl) {
        @include fs(25, 25);
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 35px;
      }
    }
    .unit {
      padding-top: 60px;
      &:first-of-type {
        padding-top: 30px;
        @include mq(tbl) {
          padding-top: 70px;
        }
      }
      @include mq(tbl) {
        padding: 70px 0 70px 30px;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
      .image {
        text-align: center;
        @include mq(tbl) {
          text-align: right;
          width: 36%;
        }
        img {
          max-width: 400px;
        }
      }
      .detail {
        margin-top: 35px;
        @include mq(tbl) {
          margin-top: 0;
          width: 60%;
          display: flex;
          flex-direction: column;
        }
        h3 {
          @include fs(22, 35);
          text-align: center;
          @include mq(tbl) {
            text-align: left;
          }
        }
        ul.feature {
          margin-top: 20px;
          @include fs(16, 30);
          @include en_regular;
          li {
            position: relative;
            padding-left: 14px;
            &:before {
              content: '';
              width: 2px;
              height: 2px;
              background-color: $black;
              display: block;
              position: absolute;
              left: 5px;
              top: 13px;
            }
          }
        }
        dl.feature {
          @include fs(16, 30);
          display: flex;
          flex-wrap: wrap;
          dt {
            color: #b3b3b3;
            width: 4em;
            margin-top: 1em;
            @include mq(tbl) {
              margin-top: .5em;
            }
          }
          dd {
            font-weight: normal;
            width: calc(100% - 4em);
            margin-top: 1em;
            @include mq(tbl) {
              margin-top: .5em;
            }
          }
        }
        ul.marks {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          @include mq(tbl) {
            margin-top: auto;
            justify-content: flex-start;
          }
          li {
            margin: 0 12px;
            @include mq(tbl) {
              margin: 0 24px 0 0;
            }
            img {
              width: 100px;
            }
          }
        }
      }
    }
  }
}
