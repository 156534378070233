/* compiled from /scss/_modules.scss */

@charset "UTF-8";
@import "variables";
@import "mixins";



.m_top_slide {
  height: 670px;
  position: relative;
  top: -75px;
  width: 100%;
  z-index: 1;
  @include mq(tbl) {
    height: 58vw;
    top: -80px;
  }
  .container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 100%;
    h1 {
      color: white;
      @include fs(34, 48);
      text-shadow: 0px 0px 5px rgba(23, 66, 95, .65);
      @include mq(tb) {
        @include fs(40, 40);
        letter-spacing: 1px;
      }
    }
  }
  #slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    div {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      &.top_mv_01 {
        background-image: url(/images/top_mv_01.jpg);
        @include mq(tb) {
          background-image: url(/images/top_mv_01_pc.jpg);
        }
      }
      &.top_mv_02 {
        background-image: url(/images/top_mv_02.jpg);
        @include mq(tb) {
          background-image: url(/images/top_mv_02_pc.jpg);
        }
      }
      &.top_mv_03 {
        background-image: url(/images/top_mv_03.jpg);
        @include mq(tb) {
          background-image: url(/images/top_mv_03_pc.jpg);
        }
      }
      &.top_mv_04 {
        background-image: url(/images/top_mv_04.jpg);
        @include mq(tb) {
          background-image: url(/images/top_mv_04_pc.jpg);
        }
      }
    }
  }
}
.m_top_news {
  .container {
    padding-left: 35px;
    padding-right: 35px;
    @include mq(tbl) {
      padding-left: 0;
      padding-right: 0;
    }
    .title {
      @include title;
    }
    ul {
      margin-top: 55px;
      @include mq(tbl) {
        margin-top: 35px;
      }
      @include newslist;
    }
  }
}
.m_policy {
  padding-top: 40px;
  padding-bottom: 60px;
  @include mq(tbl) {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  h1 {
    text-align: center;
    .en {
      color: $sub;
      @include fs(18, 28);
      letter-spacing: 5px;
      text-transform: uppercase;
      @include mq(tbl) {
        @include fs(25, 35);
        letter-spacing: 7px;
      }
    }
    .ja {
      display: block;
      @include fs(16, 16);
      letter-spacing: 1px;
      margin-top: 10px;
      @include mq(tbl) {
        margin-top: 14px;
        @include fs(20, 30);
        letter-spacing: 3px;
      }
    }
  }
  h2 {
    margin-top: 60px;
    margin-bottom: 30px;
    @include fs(15, 25);
    letter-spacing: 1px;
    text-align: center;
    @include mq(tbl) {
      @include fs(20, 30);
      letter-spacing: 2px;
      margin-bottom: 40px;
      margin-top: 110px;
    }
    &:first-of-type {
      margin-top: 40px;
      @include mq(tbl) {
        margin-top: 75px;
      }
    }
    span {
      display: inline-block;
      padding: 0 5px 7px;
      position: relative;
      @include mq(tbl) {
        padding: 0 10px 10px;
      }
      &:after {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $black;
      }
    }
  }
  p, ul, h3 {
    @include ja;
    font-weight: 500;
    @include fs(13, 24);
    letter-spacing: 1px;
    @include mq(tbl) {
      @include fs(16, 32);
      max-width: 1030px;
    }
  }
  .indent {
    display: block;
    padding-left: 2em;
  }
  p, ul {
    margin-bottom: 1.75em;
    @include mq(tbl) {
      margin-bottom: 2.25em;
    }
  }
  .purpose {
    ul {
      margin-bottom: 0;
    }
  }
}
.m_feature {
  padding-top: 110px;
  @include mq(tb) {
    padding-top: 75px;
  }
  h2 {
    @include title;
  }
  .inner {
    margin-top: 50px;
    @include mq(tb) {
      display: flex;
      flex-wrap: wrap;
      margin-top: 35px;
    }
  }
  .unit {
    background-color: #6f9ba9;
    position: relative;
    @include mq(tb) {
      width: 50%;
      &::before, &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: #97b7c0;
        content: '';
        z-index: 2;
      }
      &::after {
        bottom: auto;
        right: 0;
        width: auto;
        height: 1px;
      }
      &:nth-child(2n - 1){
        &::before {
          content: none;
        }
      }
      &:nth-child(1),&:nth-child(2) {
        &::after {
          content: none;
        }
      }
    }
    @include mq(tbl) {
      width: 25%;
      &:nth-child(2n - 1){
        &::before {
          content: '';
        }
      }
      &:nth-child(4n - 3){
        &::before {
          content: none;
        }
      }
      &:nth-child(3),&:nth-child(4) {
        &::after {
          content: none;
        }
      }
    }
    .photo {
      overflow: hidden;
      a {
        display: block;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 240px;
        transition: $photo-transition;
        @include mq(tb) {
          height: 33vw;
        }
        @include mq(tbl) {
          height: 16vw;
          &:hover {
            transform: scale(1.05);
            opacity: .7;
          }
        }
      }
    }
    .text {
      padding: 35px 30px 50px;
      @include mq(tb) {
        padding-bottom: 80px;
      }
      .date {
        @include fs(10,10);
        @include en_medium;
        letter-spacing: 2px;
      }
      h3 {
        @include fs(17,27);
        letter-spacing: 2px;
        margin-top: 6px;
        .ja & {
          @include fs(15,25);
          margin-top: 8px;
          letter-spacing: 3px;
        }
      }
      .lead {
        @include en_medium;
        @include fs(14,24);
        letter-spacing: 2px;
        margin-top: 1em;
      }
      .more {
        text-align: center;
        margin-top: 25px;
        @include mq(tb) {
          position: absolute;
          margin-top: 0;
          left: 50%;
          transform: translateX(-50%);
          bottom: 25px;
        }
        @include mq(tbl) {
          right: 25px;
          left: auto;
          transform: translateX(0);
        }
        a {
          display: inline-block;
          border: 1px solid #97b7c0;
          color: white;
          @include fs(12,12);
          padding: 9px 16px 9px 18px;
          text-transform: uppercase;
          letter-spacing: 3px;
        }
      }
    }
  }
}
.m_pickup {
  .container {
    padding-top: 100px;
    @include mq(tbl) {
      padding: 120px 0 0;
    }
    h2 {
      @include title;
    }
    .inner {
      margin-top: 40px;
      text-align: center;
      @include mq(tb) {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
      }
      .unit {
        padding-bottom: 65px;
        @include mq(tb) {
          padding-right: 15px;
          padding-left: 15px;
          flex: 1;
        }
        @include mq(tbl) {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 100px;
          width: 290px;
          flex: 0 1 auto;
        }
        @include mq(pc) {
          width: 345px;
        }
        a {
          display: block;
          @include mq(tbl) {
            &:hover {
              .photo {
                transform: scale(1.05);
                opacity: .9;
              }
            }
          }
          .photo {
            transition: $photo-transition;
            width: 85vw;
            height: 85vw;
            max-width: 400px;
            max-height: 400px;
            margin: 0 auto;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            @include mq(tb) {
              width: 100%;
              height: calc(33vw);
              max-height: none;
              max-width: none;
            }
            @include mq(tbl) {
              width: 290px;
              height: 350px;
            }
            @include mq(pc) {
              width: 345px;
              height: 420px;
            }
          }
          h3 {
            margin-top: 1.5em;
            @include fs(18, 30);
            letter-spacing: 3px;
            color: $main;
            .ja & {
              letter-spacing: 4px;
            }
          }
        }
      }
    }
  }
}
.m_banner {
  padding-bottom: 130px;
  @include mq(tbl) {
    padding-bottom: 150px;
  }
  .container {
    ul {
      border-top: 1px solid #c7cedc;
      @include mq(tb) {
        display: flex;
        border-top: none;
        border-left: 1px solid #c7cedc;
        margin-left: 15px;
        margin-right: 15px;
      }
      @include mq(tbl) {
        margin-left: 0;
        margin-right: 0;
      }
      li {
        border-bottom: 1px solid #c7cedc;
        @include mq(tb) {
          flex: 1;
          border-bottom: none;
          border-right: 1px solid #c7cedc;
        }
        a {
          height: 180px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include mq(tb) {
            height: 115px;
          }
          img {
            width: 190px;
            @include mq(tb) {
              &.mrx {
                width: 145px;
              }
              &.prize {
                width: 165px;
              }
            }
          }
        }
      }
    }
  }
}
.m_title {
  padding-top: 55px;
  padding-bottom: 30px;
  @include mq(tbl) {
    padding-top: 90px;
    padding-bottom: 35px;
  }
  &.high {
    padding-bottom: 60px;
    @include mq(tbl) {
      padding-bottom: 135px;
    }
  }
  h1 {
    @include fs(32, 32);
    letter-spacing: 10px;
    text-transform: uppercase;
    color: $sub;
    text-align: center;
    @include mq(tbl) {
      @include fs(50, 50);
      letter-spacing: 14px;
    }
  }
  nav {
    margin-top: 17px;
    position: relative;
    @include mq(tbl) {
      margin-top: 22px;
    }
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30px;
      z-index: 2;
      @include mq(tb) {
        content: none;
      }
    }
    &:before {
      left: 0;
      background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,0) 100%);
    }
    &:after {
      right: 0;
      background: linear-gradient(270deg, rgba(247,247,247,1) 0%, rgba(247,247,247,0) 100%);
    }
    .overflow {
      overflow: auto;
      padding: 15px 0;
      @include mq(tb) {
        overflow: visible;
      }
      &.flex-start {
        ul {
          justify-content: flex-start;
        }
      }
      ul {
        display: flex;
        justify-content: flex-start;
        padding: 0 35px;
        @include mq(tb) {
          justify-content: center;
        }
        &.uppercase {
          li {
            letter-spacing: 4px;
            text-transform: uppercase;
            margin-right: 60px;
            @include mq(tbl) {
              @include fs(14,14);
            }
            a {
              @include mq(tbl) {
                padding-left: 9px;
                padding-right: 7px;
              }
            }
          }
        }
        li {
          @include fs(14,14);
          letter-spacing: .5px;
          margin-right: 40px;
          white-space: nowrap;
          &:last-child {
            display: flex;
            @include mq(tb) {
              display: block;
              margin-right: 0;
            }
            &:after {
              content: '';
              display: block;
              width: 35px;
              height: 1px;
              @include mq(tb) {
                content: none;
              }
            }
          }
          @include mq(tbl) {
            @include fs(16,16);
            .ja & {
              @include fs(14,14);
              letter-spacing: 1px;
              &.wide {
                letter-spacing: 4px;
              }
            }
          }
          &.active {
            a {
              &:after {
                content: '';
                display: block;
                background-color: $sub;
                left: 0;
                right: 2px;
                height: 2px;
                position: absolute;
                bottom: 0;
              }
            }
          }
          a {
            display: block;
            padding: 10px 5px 12px 3px;
            position: relative;
            color: #595959;
            @include mq(tbl) {
              padding-right: 10px;
              padding-left: 8px;
            }
          }
        }
      }
    }
  }
}
.m_subtitle {
  padding-bottom: 30px;
  @include mq(tbl) {
    padding-bottom: 15px;
  }
  .container {
    padding-left: 35px;
    padding-right: 35px;
    @include mq(tbl) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  h2 {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    padding: 28px 0;
    @include fs(22, 32);
    letter-spacing: 2px;
    text-align: center;
    @include mq(tbl) {
      @include fs(32, 55);
      padding: 30px 0;
      .ja & {
        @include fs(30, 50);
        letter-spacing: 3px;
      }
    }
  }
}
.m_mv {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &.message_01 {
    background-image: url(/images/message_01.jpg);
    background-position: center top;
    @include mq(tb) {
      background-image: url(/images/message_01_pc.jpg);
      background-position: center;
    }
  }
  &.message_02 {
    background-image: url(/images/message_02.jpg);
    background-position: center top;
    @include mq(tb) {
      background-image: url(/images/message_02_pc.jpg);
      background-position: center;
    }
  }
  &.vision_01 {
    background-image: url(/images/vision_01.jpg);
    @include mq(tb) {
      background-image: url(/images/vision_01_pc.jpg);
    }
  }
  &.vision_02 {
    background-image: url(/images/vision_02.jpg);
    @include mq(tb) {
      background-image: url(/images/vision_02_pc.jpg);
    }
  }
  .container {
    height: 375px;
    display: flex;
    padding-bottom: 35px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: $sub;
    text-align: center;
    @include mq(tbl) {
      height: 600px;
      padding-bottom: 80px;
    }
    h2 {
      letter-spacing: 10px;
      @include fs(40,40);
      text-transform: uppercase;
      @include mq(tbl) {
        @include fs(50,50);
      }
    }
    p {
      margin-top: 4px;
      @include fs(21,40);
      letter-spacing: 1px;
      @include mq(tbl) {
        @include fs(35,50);
      }
    }
  }
}
.m_lower {
  margin-top: 20px;
  padding-bottom: 20px;
  @include mq(tbl) {
    margin-top: 10px;
  }
  .container {
    ul {
      display: flex;
      border-left: 1px solid #bfc7d5;
      li {
        flex: 1;
        border-right: 1px solid #bfc7d5;
        display: flex;
        justify-content: center;
        align-items: center;
        @include fs(14,24);
        @include mq(tbl) {
          @include fs(18,28);
          letter-spacing: 1px;
          .ja & {
            @include fs(15,25);
            letter-spacing: 2px;
          }
        }
        a {
          display: flex;
          align-items: center;
          padding: 0 15px;
          height: 105px;
          color: $main;
          text-align: center;
          @include mq(tbl) {
            height: 115px;
          }
        }
      }
    }
  }
}
.m_lnav {
  padding-top: 35px;
  padding-bottom: 30px;
  @include mq(tb) {
    padding-bottom: 80px;
  }
  @include mq(tbl) {
    padding-top: 60px;
    padding-bottom: 160px;
  }
  .container {
    nav {
      @include lnav;
    }
  }
}
.m_map {
  padding-top: 45px;
  @include mq(tb) {
    @include container;
  }
  #map {
    height: 45vw;
    overflow: hidden;
    position: relative;
    @include mq(tbl) {
      height: 580px;
    }
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 45vw;
      width: 100%;
      @include mq(tbl) {
        height: 580px;
      }
    }
  }
}
.m_access {
  @include mq(tb) {
    padding-bottom: 50px;
  }
  @include mq(tbl) {
    padding-bottom: 90px;
  }
  .container {
    .unit {
      padding-top: 50px;
      @include mq(tb) {
        padding-top: 60px;
      }
      address {
        letter-spacing: 1px;
        text-align: center;
        @include mq(tb) {
          padding-top: 42px;
        }
        h2 {
          @include fs(22,34);
          .ja & {
            @include fs(25,35);
          }
        }
        p {
          margin-top: 1.5em;
          @include fs(16,30);
          @include en_regular;
          .ja & {
            @include ja;
            @include fs(16,35);
          }
        }
      }
      .image {
        margin-top: 40px;
        @include mq(tb) {
          width: 50%;
          margin-top: 0;
        }
        img {
          max-width: 470px;
          display: block;
          margin: 0 auto 20px;
        }
      }
    }
  }
}
.m_contact {
  padding-bottom: 20px;
  color: #4c4c4c;
  letter-spacing: .5px;
  @include fs(16, 30);
  @include en_medium;
  @include mq(tbl) {
    margin-top: 10px;
  }
  .lead {
    padding-bottom: 1em;
    @include mq(tbl) {
      text-align: center;
    }
  }
  .thanks {
    margin-top: 30px;
    padding-bottom: 50px;
    text-align: center;
    @include mq(tbl) {
      margin-top: 80px;
      @include fs(16, 35);
    }
    p {
      padding-bottom: 2em;
    }
  }
  .wpcf7-response-output {
    border: none !important;
    color: $red;
    text-align: center;
  }
  .form_wrapper {
    dl {
      @include mq(tbl) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      dt {
        text-align: center;
        margin-top: 35px;
        @include en_regular;
        @include mq(tbl) {
          margin-top: 65px;
          width: 20%;
          text-align: right;
          padding-right: 40px;
        }
        &.top {
          align-self: flex-start;
        }
      }
      dd {
        margin-top: 10px;
        @include mq(tbl) {
          width: 80%;
          margin-top: 65px;
        }
        input[type="text"],input[type="email"],input[type="tel"], textarea {
          font: inherit;
          @include en_regular;
          background-color: #f0f0f0;
          @include fs(16,26);
          padding: 12px 10px;
          width: 100%;
          border-radius: 0;
          border: 1px solid #e5e5e5;
          transition: border .3s ease, background-color .3s ease;
          @include mq(tbl) {
            padding: 20px 20px;
          }
          &:focus {
            background-color: darken(#f0f0f0, 5%);
          }
          &.wpcf7-not-valid {
            background-color: #fff0f1;
          }
        }
        textarea {
          height: 200px;
          max-width: 100%;
          @include mq(tbl) {
            height: 335px;
          }
        }
        .wpcf7-not-valid-tip {
          color: $red;
          @include fs(14,24);
          display: block;
          margin: 2px 0 0;
          padding-bottom: 10px;
        }
      }
    }
    .policy {
      margin-top: 30px;
      text-align: center;
      @include mq(tbl) {
        margin-top: 100px;
      }
      input[type="checkbox"] {
        margin-left: 10px;
      }
      a {
        text-decoration: underline;
      }
    }
    .btn {
      text-align: center;
      margin-top: 40px;
      @include mq(tbl) {
        margin: 100px 0 0;
        padding-bottom: 100px;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        flex-wrap: wrap;
      }
      input[type="submit"],input[type="button"] {
        font: inherit;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        @include fs(18,18);
        color: white;
        text-align: center;
        background-color: $sub;
        width: 240px;
        padding: 16px 14px;
        position: relative;
        border-radius: 0;
        letter-spacing: 2px;
        transition: background .3s ease;
        &.wpcf7c-btn-back {
          margin-top: 25px;
          background-color: #d1d1d1;
          color: $black;
          @include mq(tb) {
            margin-top: 0;
            margin-right: 30px;
          }
          @include mq(tbl) {
            width: 340px;
            margin-right: 70px;
            &:hover {
              background-color: $black;
              color: white;
            }
          }
        }
        &.wpcf7-submit {
          @include mq(tbl) {
            width: 340px;
          }
        }
        @include mq(tbl) {
          padding: 24px 15px;
          width: 590px;
          &:hover {
            background-color: $main;
          }
        }
      }
      .ajax-loader {
        display: none;
      }
    }
  }
}
.m_uc {
  text-align: center;
  color: #4c4c4c;
  letter-spacing: .5px;
  @include fs(16, 30);
  @include en_medium;
  .container {
    padding-top: 150px;
    padding-bottom: 100px;
    @include mq(tbl) {
      padding-top: 250px;
      padding-bottom: 200px;
    }
  }
}
.m_links {
  margin-top: 85px;
  padding-bottom: 50px;
  @include mq(tbl) {
    padding-bottom: 100px;
  }
  .container {
    ul {
      border-top: 1px solid #E0E0E0;
      li {
        border-bottom: 1px solid #E0E0E0;
        a {
          display: block;
          text-align: center;
          padding: 36px 0;
          @include fs(15, 25);
          color: #183670;
          @include mq(tbl) {
            text-align: left;
            padding: 36px 130px;
            @include fs(18, 32);
          }
        }
      }
    }
  }
}
.m_disclosure_lead {
  .container {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    @include mq {
      padding-top: 30px;
      padding-bottom: 30px;

    }
    .text {
      @include fs(18,32);
    }
    .date {
      @include fs(14,24);
      margin-top: 3px;
    }
  }

}
.m_disclosure_purposes {
  .container {
    padding-top: 50px;
    h2 {
      margin-top: 50px;
      @include fs(18,32);
      &:first-of-type {
        margin-top: 0;
      }
      + p {
        margin-top: .5em;
      }
    }
    p {
      margin-top: 1em;
      @include fs(16,32);
      font-weight: normal;
    }
  }
}
.m_disclosure_data {
  background-color: #EBEBEB;
  margin-top: 75px;
  .container {
    padding-top: 55px;
    padding-bottom: 23px;
    @include mq() {
      padding-bottom: 60px;
    }
    h2 {
      @include fs(21,26);
      text-align: center;
      @include mq() {
        text-align: left;
      }
    }
    dl {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 11px;
      border-bottom: 1px solid #E0E0E0;
      padding-bottom: 20px;
      @include mq() {
        justify-content: flex-start;
      }
      dt {
        .select_wrap {
          position: relative;
          display: flex;
          width: 140px;
          height: 46px;
          line-height: 46px;
          background: white;
          overflow: hidden;
          border: 1px solid #A5A5A5;
          &::after {
            top: 50%;
            right: 16px;
            content: "";
            height: 7px;
            width: 14px;
            position: absolute;
            pointer-events: none;
            transform: translateY(-50%);
            background-image: url(/images/select_arrow.svg) ;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
          select {
            appearance: none;
            outline: 0;
            box-shadow: none;
            border: 0 !important;
            background: white;
            background-image: none;
            flex: 1;
            padding: 0 15px;
            font: inherit;
            font-size: 16px;
            cursor: pointer;
            &::-ms-expand {
              display: none;
            }
          }
        }
      }
    }
    #data {
      padding-top: 20px;
      .tables {
        .term {
          @include fs(16,32);
          font-weight: normal;
          br {
            @include mq() {
              display: none;
            }
          }
        }
        h3 {
          @include fs(16,32);
          margin-top: 40px;
        }
        .nodata {
          background-color: #F7F7F7;
          padding: 12px 20px;
          @include fs(16,24);
          margin-top: 10px;
          text-align: center;
          font-weight: normal;
          @include mq(tbl) {
            text-align: left;
            padding: 14px 16px;
          }

        }
        .datatable-pc {
          display: none;
          margin-top: 15px;
          width: 100%;
          @include mq(tbl) {
            display: table;
          }
          th, td {
            @include fs(15,24);
            padding: 18px 15px;
            vertical-align: middle;
          }
          th {
            background-color: #A5A5A5;
            border-right: 1px solid #F7F7F7;
            color: #F7F7F7;
            white-space: nowrap;
            &.amount {
              width: 70px;
            }
            &.price {
              width: 150px;
            }
          }
          td {
            border-top: 1px solid #E0E0E0;
            background-color: #F7F7F7;
            &:not(:last-child) {
              background-image : linear-gradient(to bottom, #e0e0e0 2px, transparent 2px);
              background-position: right;
              background-size: 1px 4px;
              background-repeat: repeat-y;
            }
            &.num {
              text-align: right;
            }
          }
        }
        .datatable-sp {
          width: 100%;
          @include mq(tbl) {
            display: none;
          }
          thead {
            display: none;
          }
          tr {
            display: block;
            background-color: #F7F7F7;
            padding: 15px 20px;
            margin-top: 10px;
            th, td {
              display: block;
              @include fs(16,24);
            }
            th {
              color: #A5A5A5;
              margin-top: 20px;
              font-weight: bold;
              &:first-of-type {
                margin-top: 0;
              } 
              br {
                display: none;
              }
            }
            td {
              font-weight: normal;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
.m_2023_prize_movie {
  padding-bottom: 100px;
  margin-top: 40px;
  @include mq(tbl) {
    margin-top: 80px;
    padding-bottom: 200px;
  }
  .m_banner + & {
    margin-top: -40px;
  }
  .container {
    h2 {
      text-align: center;
      @include fs(15,15);
      letter-spacing: .5px;
      @include mq(tbl) {
        @include fs(16,16);
      }
      span {
        position: relative;
        display: inline-block;
        padding: 0 5px 10px;
        @include mq(tbl) {
          padding: 0 5px 12px;
        }
        &:after {
          content: '';
          display: block;
          background-color: $sub;
          left: 0;
          right: 2px;
          height: 2px;
          position: absolute;
          bottom: 0;
        }
        i {
          font-style: italic;
        }
      }
    }
    p {
      margin-top: 30px;
      @include fs(14,21);
      letter-spacing: .5px;
      font-weight: 500;
      @include mq(tbl) {
        margin-top: 45px;
        @include fs(15,25);
        letter-spacing: .9px;
      }
      i {
        font-style: italic;
      }
    }
    .movie {
      margin-top: 15px;
      @include mq(tbl) {
        margin-top: 30px;
      }
      video {
        width: 100%;
      }
    }
  }

}