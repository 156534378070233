@charset "UTF-8";
@import "variables";

@mixin mq($breakpoint: tb) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin container {
  padding-left: 25px;
  padding-right: 25px;
  &.narrow {
    padding-left: 35px;
    padding-right: 35px;
    @include mq(tbl) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include mq(tbl) {
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
  }
  @include mq(pc) {
    max-width: none;
    width: 1220px;
    &.narrow {
      width: 960px;
    }
  }
}
@mixin containerNarrow {
  width: $containerNarrow;
  margin-right: auto;
  margin-left: auto;
}
@mixin hoverOp($op:0.5) {
  transition: opacity .3s ease;
  &:hover {
    opacity: $op;
    text-decoration: inherit;
  }
}
@mixin hide-text() {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}
@mixin sprite-background-legacy($name) {
  width: image-width(sprite-file($replace, $name));
  height: image-height(sprite-file($replace, $name));
  $xpos: nth(sprite-position($replace, $name), 1);
  $ypos: nth(sprite-position($replace, $name), 2);
  background-image: $replace-img;
  background-position: $xpos $ypos;
  background-repeat:no-repeat;
  $wbgz: image-width(sprite-path($replace));
  @include background-size($wbgz auto);
  display: block;
}
@mixin sprite-background($name) {
  width: image-width(sprite-file($replace, $name)) / 2;
  height: image-height(sprite-file($replace, $name)) / 2;
  $xpos: nth(sprite-position($replace, $name), 1) / 2;
  $ypos: nth(sprite-position($replace, $name), 2) / 2;
  background-image: $replace-img;
  background-position: $xpos $ypos;
  background-repeat:no-repeat;
  $wbgz: round(image-width(sprite-path($replace)) / 2);
  @include background-size($wbgz auto);
  display: block;
}
@mixin sprite-background-4x($name) {
  width: image-width(sprite-file($replace, $name)) / 4;
  height: image-height(sprite-file($replace, $name)) / 4;
  $xpos: nth(sprite-position($replace, $name), 1) / 4;
  $ypos: nth(sprite-position($replace, $name), 2) / 4;
  background-image: $replace-img;
  background-position: $xpos $ypos;
  background-repeat:no-repeat;
  $wbgz: round(image-width(sprite-path($replace)) / 4);
  @include background-size($wbgz auto);
  display: block;
}
@mixin sprite-replace-legacy($name) {
  @include sprite-background-legacy($name);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin sprite-replace($name) {
  @include sprite-background($name);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin sprite-replace-4x($name) {
  @include sprite-background-4x($name);
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 10px;
    button {
      border: none;
      background-color: #ccc;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 5px;
    }
    &.slick-active {
      button {
        background-color: #b2b2b2;
      }
    }
  }
}
@mixin en {
  font-family: proxima-nova,sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin en_medium {
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin en_regular {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin ja {
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
}
@mixin title {
  text-align: center;
  color: $main;
  @include fs(19,19);
  text-transform: uppercase;
  letter-spacing: 5px;
  @include mq(tbl) {
    @include fs(25,25);
    letter-spacing: 6px;
  }
  span {
    border-bottom: 3px solid $main;
    display: inline-block;
    padding: 0 2px 5px 4px;
    @include mq(tbl) {
      padding: 0 2px 8px 4px;
    }
  }
}
@mixin article {
  @include en_regular;
  > *:first-child {
    margin-top: 0;
  }
  h2, h3, h4, h5, h6 {
    @include fs(16,26);
    margin: 25px 0;
    @include en;
  }
  p, ol, ul {
    @include fs(16,32);
    margin-bottom: 1em;
    a {
      color: $sub;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  p {
    img[class*="wp-image"] {
      margin: 30px auto;
      display: block;
      width: auto;
    }
  }
  ol {
    list-style: decimal;
    li {
      margin-left: 20px;
    }
  }
  ul {
    list-style: disc;
    li {
      margin-left: 20px;
    }
  }
  figure[class*="wp-caption"],
  div[class*="wp-caption"] {
    max-width: 100%;
    padding: 15px 0;
    margin-left: auto;
    margin-right: auto;
    @include mq(tbl) {
      padding: 30px 0;
    }
    img {
      max-width: 100%;
    }
    figcaption,
    .wp-caption-text {
      text-align: center;
      @include fs(14,28);
      margin-top: 12px;
      @include mq(tbl) {
        @include fs(16,34);
      }
    }
  }
  blockquote {
    margin-top: 20px;
    background-color: #ebebeb;
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
    padding: 12px 16px;
    @include fs(13, 22);
    @include en_regular;
    @include mq(tbl) {
      margin-top: 30px;
      @include fs(14, 24);
      padding: 26px 30px;
    }
  }
}
@mixin newslist {
  border-bottom: 1px solid #e6e6e6;
  li {
    border-top: 1px solid #e6e6e6;
    a {
      display: block;
      padding: 22px 0 26px;
      @include mq(tbl) {
        display: flex;
        padding: 20px 0 18px;
      }
      .date {
        @include fs(14,24);
        letter-spacing: 2px;
        padding-bottom: 10px;
        @include en_regular;
        white-space: nowrap;
        .ja & {
          @include ja;
        }
        @include mq(tbl) {
          width: 140px;
          padding-left: 10px;
          padding-bottom: 0;
        }
      }
      h2 {
        @include fs(14,24);
        letter-spacing: 1px;
        .ja & {
          font-weight: normal;
          letter-spacing: 2px;
        }
        @include mq(tbl) {
          flex: 1;
        }
      }
    }
  }
}
@mixin lnav {
  position: relative;
  height: 32px;
  @include mq(tbl) {
    height: 50px;
  }
  .prev, .next {
    display: block;
    position: absolute;
    height: 32px;
    @include mq(tbl) {
      height: 50px;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 16px auto;
      width: 16px;
      height: 32px;
      top: 0;
      @include mq(tbl) {
        background-size: 24px auto;
        width: 24px;
        height: 50px;
      }
    }
    span {
      display: none;
      @include mq(tb) {
        color: #595959;
        display: block;
        @include fs(14,14);
        letter-spacing: 4px;
        line-height: 32px;
        .ja & {
          letter-spacing: 2px;
        }
        @include mq(tbl) {
          @include fs(15,15);
          line-height: 50px;
        }
      }
    }
  }
  .prev {
    &:after {
      background-image: url(/images/prev.svg);
    }
    span {
      padding-left: 30px;
      @include mq(tbl) {
        padding-left: 40px;
      }
    }
  }
  .next {
    right: 0;
    &:after {
      right: 0;
      background-image: url(/images/next.svg);
    }
    span {
      text-align: right;
      padding-right: 30px;
      @include mq(tbl) {
        padding-right: 40px;
      }
    }
  }
  .back {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    @include fs(14,14);
    letter-spacing: 4px;
    @include mq(tbl) {
      top: 10px;
    }
    span {
      display: inline-block;
      border-bottom: 2px solid $main;
      padding: 4px 4px 4px;
      .ja & {
        letter-spacing: 2px;
      }
      @include mq(tbl) {
        padding: 10px 4px 10px;
      }
    }
  }
}
