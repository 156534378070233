@charset "UTF-8";
@import "reset";
@import "variables";
@import "mixins";

@import url("https://use.typekit.net/lrb8tvo.css");

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
outline: none;
}
html {
  font-size: 62.5%;
  height: 100%;
  @include mq(pc) {
    min-width: $container;
  }
}
body {
  @include fs(14);
  color: $black;
  background-color: $main;
  -webkit-text-size-adjust: 100%;
  font-family: proxima-nova,sans-serif,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
  font-weight: 600;
  font-style: normal;
  .ja & {
    font-weight: normal;
  }
  &:after {
    // content: 'min';
    position: fixed;
    left: 10px;
    top: 10px;
    font-size: 20px;
    background-color: red;
    color: white;
    z-index: 1000;
    @include mq(tb) {
      // content: 'tb';
    }
    @include mq(tbl) {
      // content: 'tbl';
    }
    @include mq(pc) {
      // content: 'pc';
    }
  }
}
strong {
  font-weight: bold;
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: $black;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  @include mq(tbl) {
    @include hoverOp();
  }
  &:hover {
    text-decoration: none;
  }
  &[href^="tel:"] {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}
button {
  outline: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 1rem;
  @include mq(tbl) {
    font-size: .7rem;
  }
}
sup {
  bottom: 1ex;
}
sub {
  top: 1ex;
}
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  @media (min-width: $screen-sm-min) {
    max-width: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none !important;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none !important;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none !important;
  }
}
.hidden-over-pc {
  @include mq(pc) {
    display: none !important;
  }
}
.hidden-sp {
  @media (max-width: $screen-sm-min) {
    display: none !important;
  }
}
.hidden-tb {
  @media (max-width: 768px) {
    display: none !important;
  }
}
.hidden-tbl {
  @media (max-width: 1060px) {
    display: none !important;
  }
}
.hidden-pc {
  @media (max-width: 1300px) {
    display: none !important;
  }
}
main {
  background-color: #f7f7f7;
  display: block;
}
.container {
  @include container;
}
@import "header";
@import "footer";
@import "modules";
@import "contents";
@import "metabolome";
@import "news";
@import "feature";
@import "slick";
@import "animsition";
