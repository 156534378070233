/* compiled from /scss/_header.scss */

@charset "UTF-8";
@import "variables";
@import "mixins";




header {
  background-color: #f7f7f7;
  position: relative;
  z-index: 2;
  @include mq(tbl) {
    padding: 0;
    height: auto;
  }
  .util {
    display: none;
    position: relative;
    z-index: 10;
    @include mq(tbl) {
      display: block;
      background-color: #333;
    }
    ul {
      @include container;
      display: flex;
      justify-content: flex-end;
      position: relative;
      @include mq(tbl) {
        padding-left: 0;
        padding-right: 0;
      }
      li {
        margin-left: 85px;
        span, a {
          display: block;
          color: #bfbfbf;
          @include en_regular;
          @include hoverOp;
          letter-spacing: 2px;
          text-transform: uppercase;
          @include fs(10,10);
          padding: 15px 0 12px;
          cursor: pointer;
        }
        ul {
          position: absolute;
          right: 0;
          padding-left: 0;
          padding-right: 0;
          background-color: #595959;
          display: flex;
          justify-content: flex-end;
          width: auto;
          opacity: 0;
          z-index: 11;
          transition: opacity .4s ease;
          &.active {
            opacity: 1;
          }
          li {
            margin-left: 0;
            position: relative;
            &:before {
              content: '';
              display: block;
              position: absolute;
              width: 1px;
              height: 18px;
              background-color: #6b6b6b;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            &:first-child {
              &:before {
                content: none;
              }
            }
            a {
              white-space: nowrap;
              text-align: center;
              width: 210px;
              padding: 12px 0;
              @include fs(11,11);
              letter-spacing: 1px;
              text-transform: none;
              color: #d9d9d9;
            }
            .ja & {
              a {
                @include fs(12,12);
              }
            }
          }
        }
      }
    }
  }
  .bottom {
    .inner {
      @include container;
      padding: 25px 25px 0;
      height: 75px;
      position: relative;
      z-index: 2;
      @include mq(tbl) {
        padding: 40px 0 0;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      #logo {
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
          img {
            width: 173px;
            height: auto;
            @include mq(tbl) {
              width: 240px;
            }
          }
        }
      }
      ul {
        display: none;
        @include mq(tbl) {
          display: flex;
        }
        li {
          @include fs(13,13);
          letter-spacing: 4px;
          text-transform: uppercase;
          &:last-child {
            a {
              padding-right: 0;
              .ja & {
                padding-right: 0;
              }
            }
          }
          a {
            display: block;
            @include hoverOp();
            padding: 0 20px;
            color: #595959;
            .top & {
              color: white;
              text-shadow: 0px 0px 5px rgba(23, 66, 95, .85)
            }
            @include mq(tbl) {
              padding: 10px 20px;
              .ja & {
                padding: 10px 20px;
              }
            }
            @include mq(pc) {
              padding: 10px 30px;
              .ja & {
                padding: 10px 30px;
              }
            }
          }
        }
      }
    }
  }
}
#covernav {
  @include mq(tbl) {
    display: none;
  }
  #menuButton {
    position: fixed;
    right: 19px;
    top: 19px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 999;
    @include mq(tbl) {

    }
    @include mq(pc) {
      right: 50%;
      margin-right: -610px;
    }
    div {
      position: absolute;
      right: 0;
      width: 30px;
      left: 5px;
      height: 1px;
      background: #adb7cc;
      transition: all .4s ease;
      .top & {
        background: white;
      }
      &.line01 {
        top: 11px;
      }
      &.line02 {
        top: 19px;
      }
      &.line03 {
        top: 27px;
      }
    }
    &.show {
      div {
        transition: transform .4s ease;
        width: 30px;
        left: 5px;
        background-color: #e5e5e5;
        &.line01 {
          top: 20px;
          transform: rotateZ(45deg);
        }
        &.line02 {
          top: 20px;
          transform: rotateZ(-45deg);
        }
        &.line03 {
          background: rgba(255, 255, 255, 0) !important;
        }
      }
    }
  }
  #gnav {
    display: none;
    background-color: #000;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 899;
    text-align: center;
    color: #bfbfbf;
    a {
      color: #bfbfbf;
    }
    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      @media screen and (max-height: 540px) and (orientation: landscape){
        flex-direction: row;
        .unit {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 40%;
          dl {
            margin-top: 10px;
          }
        }
      }
    }
    .main {
      li {
        a {
          display: block;
          padding: 13px 0;
          text-align: center;
          text-transform: uppercase;
          @include fs(17,17);
          letter-spacing: 4px;
          @media screen and (max-height: 540px) and (orientation: landscape){
            @include fs(14,14);
            padding: 8px 0;
          }
          @media screen and (max-height: 600px) and (orientation: portrait){
            @include fs(15,15);
            padding: 10px 0;
          }
        }
      }
    }
    dl {
      margin-top: 55px;
      @media screen and (max-height: 600px) and (orientation: portrait){
        margin-top: 25px;
      }
      dt {
        @include en_medium;
        @include fs(13,13);
        padding-bottom: 7px;
        text-transform: uppercase;
        letter-spacing: 2px;
        @media screen and (max-height: 600px) and (orientation: portrait){
          @include fs(11,11);
        }
      }
      dd {
        @include fs(17,17);
        letter-spacing: 2px;
        @media screen and (max-height: 540px) and (orientation: landscape){
          @include fs(14,14);
        }
        @media screen and (max-height: 600px) and (orientation: portrait){
          @include fs(15,15);
        }
        .ja & {
          margin-top: 3px;
          @include fs(15,15);
          @media screen and (max-height: 540px) and (orientation: landscape){
            @include fs(13,13);
          }
          @media screen and (max-height: 600px) and (orientation: portrait){
            @include fs(14,14);
          }
        }
        a {
          display: block;
          padding: 10px 0;
          @media screen and (max-height: 600px) and (orientation: portrait){
            padding: 8px 0;
          }
        }
      }
    }
    .lang {
      margin-top: 55px;
      @media screen and (max-height: 600px) and (orientation: portrait){
        margin-top: 15px;
      }
      a {
        display: block;
        padding: 10px 0;
        @include fs(17,17);
        letter-spacing: 4px;
        text-transform: uppercase;
        @media screen and (max-height: 540px) and (orientation: landscape){
          @include fs(14,14);
        }
        @media screen and (max-height: 600px) and (orientation: portrait){
          @include fs(15,15);
        }
      }
    }
  }
}
