/* compiled from /scss/_metabolome.scss */

@charset "UTF-8";

@import "variables";
@import "mixins";

.m_contents {
  &.metabolome {
    h2 {
      border-bottom: 1px solid #e6e6e6;
      @include fs(20,30);
      @include en;
      margin-top: 70px;
      padding-bottom: 10px;
      text-align: center;
      letter-spacing: 1px;
      &:first-of-type {
        margin-top: 20px;
      }
      &.noborder {
        border: none;
        padding-bottom: 0;
      }
      .ja & {
        @include ja;
        font-weight: bold;
      }
      @include mq(tbl) {
        margin-top: 150px;
        @include fs(32,42);
        padding-bottom: 25px;
        .ja & {
          @include fs(25,35);
        }
        &:first-of-type {
          margin-top: 50px;
        }
      }
    }
    h3 {
      @include fs(16, 24);
      @include en;
      margin-top: 2em;
      margin-bottom: 2em;
      background-color:#029087;
      color: white;
      padding: 10px 20px;
      @include mq(tbl) {
        margin-top: 50px;
      }
      + h4 {
        @include mq(tbl) {
          margin-top: -15px;
        }
      }
      .ja & {
        @include ja;
        font-weight: bold;
        @include fs(16, 24);
        letter-spacing: 1px;
      }
    }
    h4 {
      @include fs(16, 30);
      @include en;
      margin-top: 1em;
      padding-bottom: 1em;
      @include mq(tbl) {
        text-align: justify;
      }
      + table {
        margin-top: 0;
      }
      .ja & {
        @include ja;
        font-weight: bold;
        @include fs(16, 34);
        letter-spacing: 1px;
      }
    }
    table {
      margin-top: 15px;
      @include mq(tbl) {
        margin-top: 30px;
        border-bottom: 1px solid #e6e6e6;
      }
      + p {
        @include mq(tbl) {
          margin-top: 2em;
        }
      }
      tr {
        th {
          display: block;
          background-color: #EBEBEB;
          padding: 14px 18px;
          @include fs(16, 16);
          @include en;
          @include mq(tbl) {
            @include fs(16, 28);
            width: 300px;
            text-align: center;
            display: table-cell;
            padding: 14px 20px;
            border-top: 1px solid #e6e6e6;
            &:last-child {
              border-right: none;
            }
          }
        }
        td {
          @include fs(16, 28);
          display: block;
          padding: 14px 0 30px;
          @include mq(tbl) {
            @include fs(16, 32);
            display: table-cell;
            vertical-align: top;
            padding: 14px 30px;
            border-top: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
          }
          ul {
            display: flex;
            justify-content: center;
            @include mq(tbl) {
              justify-content: flex-start;
              padding: 5px 0;
            }
            li {
              padding: 0 16px;
              @include mq(tbl) {
                display: flex;
                padding: 0;
                margin-right: 50px;
              }
              p {
                padding-bottom: 12px;
                @include mq(tbl) {
                  margin-right: 22px;
                  padding-bottom: 0;
                }
              }
              img {
                width: 120px;
              }
            }
          }
        }
      }
    }
    .library {
      background-color: white;
      max-width: 700px;
      padding: 20px 20px;
      margin: 0 auto 60px;
      @include mq(tbl) {
        padding:20px 10px;
      }
    }
    .imagefig {
      background-color: white;
      max-width: 700px;
      padding:20px 20px;
      margin: 0 auto 60px;
      text-align: center;
      @include mq(tbl) {
        padding:25px 10px;
      }
      img {
        max-width: 500px;
        &:last-child {
          margin-top: 20px;
        }
      }
    }
    .applications {
      @include mq(tbl) {
        display: flex;
        flex-wrap: wrap;
      }
      .unit {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 30px;
          width: 48%;
          margin-right: 4%;
          &:nth-child(even) {
            margin-right: 0;
          }
        }
        h4 {
          color: #009599;
          @include fs(16, 30);
          @include en;
          margin-top: 1em;
          padding-bottom: 0;
          @include mq(tbl) {
            margin-top: 1.5em;
          }
          .ja & {
            @include ja;
            letter-spacing: 1px;
            @include fs(16, 34);
          }
        }
        p {
          margin-top: 0;
        }
      }
    }
    .list {
      @include mq(tbl) {
        columns: 2;
        column-gap: 20px;
      }
      li {
        @include fs(16, 30);
        @include en_regular;
        i {
          font-style: italic;
        }
        &.right {
          text-align: right;
          .ja & {
            @include ja;
            letter-spacing: 1px;
          }
        }
      }
    }
    .report {
      background-color: white;
      max-width: 700px;
      padding:20px 20px;
      margin: 0 auto 20px;
      text-align: center;
      @include mq(tbl) {
        padding:25px 20px;
      }
      img {
        &:last-child {
          margin-top: 20px;
        }
      }
    }
    .flow {
      padding-bottom: 30px;
      @include mq(tbl) {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;
      }
      .unit {
        @include mq(tbl) {
          width: 46%;
          margin-right: 8%;
          &:nth-child(even) {
            margin-right: 0;
          }
        }
        h3 {
          background-color: #EBEBEB;
          color: #029087;
          border-left: 2px solid #029087;
          margin-top: 1.5em;
          margin-bottom: 15px;
        }
      }
    }
    .contact {
      margin-top: 35px;
      background-color: #EBEBEB;
      padding: 12px;
      @include mq(tbl) {
        margin-top: 50px;
        padding: 25px;
      }
      a {
        display: block;
        text-align: center;
        @include fs(16, 24);
        @include en;
        background-color:#029087;
        color: white;
        padding: 15px 20px;
        transition: background .3s ease;
        .ja & {
          @include ja;
          font-weight: normal;
          letter-spacing: 1px;
        }
        @include mq(tbl) {
          @include fs(20, 35);
          padding: 20px 20px;
          &:hover {
            background-color: $main;
            opacity: 1;
          }
        }
      }
    }
  }
  &.gmaservice {
    .imagefig {
      padding:0;
      background-color: transparent;
      @include mq(tbl) {
        padding:0;
      }
      img {
        max-width: none;
      }
    }
    .table_scroll {
      overflow-x: scroll;
      @include mq(tbl) {
        overflow: auto;
      }
      table.plans {
        margin-top: 0;
        @include mq(tbl) {
          margin-top: 0;
        }
        th {
          white-space: nowrap;
        }
        td {
          white-space: nowrap;
        }
      }
    }
    table.plans {
      border-bottom: 1px solid #e6e6e6;
      border-left: 1px solid #e6e6e6;
      &.pack {
        border-color: #c8c8c8;
        tr {
          &:nth-child(2){
            td {
              color: #029087;
            }
          }
          th {
            border-color: #c8c8c8;
            background-color: #C5E5E4;
          }
          td {
            background-color: #DDF0EF;
            border-color: #c8c8c8;
          }
        }
      }
      tr {
        th {
          display: table-cell;
          border-top: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          text-align: center;
          padding: 14px 20px;
          @include fs(16, 22);
          font-weight: normal;
          &.main {
            text-align: left;
            font-weight: bold;
          }
        }
        td {
          display: table-cell;
          border-top: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          text-align: center;
          padding: 14px 20px;
        }
      }
    }
  }
}
